import React, {useState} from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Products from "../Components/Products";
// import { FaUserTie } from "react-icons/fa";
import { Slider, Branches } from "../Components";
import OwlCarousel from "react-owl-carousel";
import {
  // AboutUsImg,
  // ServiceImg,
  // WhoWeAre,
  // Years,
  MotivusAboutusHome,
  Certifications,
  CertificationsOne,
  CertificationsTwo,
  CertificationsThree,
  CertificationsFour,
  CertificationsFive,
  CertificationsSix,
  Association1,
  Association2,
  Association3,
  Association4,
  Association5,
  Association6,
  Adani,
  Sterlite,
  Ambuja,
  Supportingus1,
  Supportingus2,
  Supportingus3,
  Supportingus4,
  Supportingus5,
  // OURCHALLENGES,
  // BatteryImg,
  SMPS,
  // Digi,
  // Under,
  // Blind,
  // VR,
  // VRLA,
  // MotivusAboutusInnerPage,
  digitalbattery,
  bms,
  Supplies,
  fiberSolar,
  bamboo,
  projectMgmt,
  iot,
  Tata
} from "../images";
import { Link } from "react-router-dom";

const Home = () => {
  // State to track active tab
  const [activeKey, setActiveKey] = useState("Mission");

  // Map each tab to a specific background image
  const backgrounds = {
    Mission: "url(./images/mission-bg.jpeg)",
    Vision: "url(./images/mission-bg.jpeg)",
    Value: "url(./images/mission-bg.jpeg)"
  };
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    loop: true,
    dots: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 1,
      },
      1024: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  };
  const clients = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    autoplay: false,
    loop: true,
    dots: false,
    smartSpeed: 800,
    responsive: {
      0: {
        items: 2,
      },
      430: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 4,
      },
      1024: {
        items: 6,
      },
    },
  };
  return (
    <>
      <Slider />
      <div className="about-sec">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <div className="about-img">
                <img src={MotivusAboutusHome} alt="" />
                {/* <img className="years" src={Years} alt="" /> */}
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="about_content">
                <h3 className="heading">
                  <span>About Us</span>
                </h3>
                <p>
                Motivation Innovation group is a Technology Innovation Company, invested in Research & Development and Manufacturing to contribute in the Environment, Sustainability and Governance Sector Globally.
                <br/><br/>
                The founders and management come with substantial experience in the fields of Telecommunications, Power Electronics, Fiber and Software. The management has acquired expertise not only in India, but also in other Southeast Asian countries, such as Malaysia, Myanmar, Bangladesh, Pakistan, Cambodia, Indonesia, Sri Lanka and the Philippines.
                </p>
                {/* <p>
                Motivation Innovation group is a Technology Innovation Company, Invested in Research & Development and Manufacturing to contribute in the ESG Sector Globally.
                </p> */}
                
                {/* <p>
                Motivus Innovation Private Limited as a technology innovation company. Head Quartered in Noida we serve customers Pan India. A highly professional management, with a collective experience of more than 150 years in various industries spanning Telecommunications, Renewable Energy, Power Electronics, Fibre, and Transmission, in India as well as other South East Asian countries, like Malaysia, Myanmar, Bangladesh, Pakistan, Sri Lanka, Cambodia, Philippines, Indonesia and Thailand.
                </p> */}
               
                <Link to="about-us">Read More</Link>
                <h1 className="sub-heading">
                  <span>About</span>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="service-section branches-sec">
        <Container>
          <Row>
            <div className="pbmit-heading-subheading center-align text-center">
              <h4 className="pbmit-title">Our Services</h4>
              <p>The company is involved in identifying operational challenges in various industries and providing Innovative Solutions. We have the following solutions in our portfolio currently:</p>
            </div>
          </Row>
          <Row>
            <OwlCarousel className="slider-items owl-carousel owl-home" {...options}>
              {/* <div className="item">
                <Products image={BatteryImg} title={"Battery Regeneration"} />
              </div> */}

              <div className="item">
                <Products
                  image={digitalbattery}
                  title={"Digital Battery Regeneration"}
                  link={'/company/11'}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              <div className="item">
                <Products
                  image={bms}
                  title={"Battery Management System for VRLA Batteries"}
                  link={'/company/11'}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              <div className="item">
                <Products
                  title={"Power Systems Management"}
                  link={'/company/11'}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              <div className="item">
                <Products
                  image={SMPS}
                  title={"SMPS Core Upgradation"}
                  link={'/company/11'}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }  
                />
              </div>
              <div className="item">
                <Products
                  image={bamboo}
                  title={"Bamboo Composites based Structure"}
                  link={'/company/12'}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }  
                />
              </div>
              <div className="item">
                <Products
                  image={iot}
                  title={"Embedded IOT & AI based software Services"}
                  link={'/company/14'}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }  
                />
              </div>
              <div className="item">
                <Products
                  image={projectMgmt}
                  title={"Project Management and Development"}
                  link={'/company/13'}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }  
                />
              </div>
              <div className="item">
                <Products
                  image={fiberSolar}
                  title={"Fiber and Solar Deployment"}
                  link={'/company/13'}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }  
                />
              </div>
              {/*
              <div className="item">
                <Products
                  image={Under}
                  title={"Underground DG Bunkers"}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              */}
              <div className="item">
                <Products
                  image={Supplies}
                  title={"Supplies"}
                  link={'/company/13'}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              {/*
              <div className="item">
                <Products
                  image={Blind}
                  title={"IOT Based Blind Sticks and Wheel Chairs"}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              <div className="item">
                <Products
                  image={VR}
                  title={"VR Gear based Training Modules"}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              <div className="item">
                <Products
                  image={MotivusAboutusInnerPage}
                  title={"Fleet Management Systems"}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              */}
            </OwlCarousel>
          </Row>
        </Container>
      </div>
      <div className="statements-section" style={{ backgroundImage: backgrounds[activeKey] }}>
        <Container>
          <Row>
            <Col sm="12">
              <div className="tab-sec">
              <Tabs
        id="uncontrolled-tab-example"
        activeKey={activeKey}
        onSelect={(key) => setActiveKey(key)}
        className="mb-3"
      >
        <Tab eventKey="Mission" title="Mission statements">
          <Row className="justify-content-end">
            <Col md="12" lg="6">
              <div className="content-box">
                <h5>Mission Statement</h5>
                <ul>
                  <li>
                    <span className="text-success">→</span> To always strive to give the best quality to the customer
                  </li>
                  <li>
                    <span className="text-success">→</span> To always strive to deal with customers with utmost Professionalism and Integrity
                  </li>
                  <li>
                    <span className="text-success">→</span> To keep always strive to drive Sustainability initiatives to help the Environment and Governance.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Tab>
        
        <Tab eventKey="Vision" title="Vision statements">
          <Row className="justify-content-end">
            <Col md="12" lg="6">
              <div className="content-box">
                <h5>Vision Statement</h5>
                <p>To be known as the hallmark of quality, precision and professionalism in the fields of supplies and services.</p>
              </div>
            </Col>
          </Row>
        </Tab>
        
        <Tab eventKey="Value" title="CORE VALUES">
          <Row className="justify-content-end">
            <Col md="12" lg="6">
              <div className="content-box">
                <h5>CORE VALUES</h5>
                <p><i><strong className="color-logo">Ability to Listen:</strong></i> Motivus believes in keeping the ears open to listen to the issues plaguing the industries and identifying gaps.</p>
                <p><i><strong className="color-logo">Innovation:</strong></i> Motivus constantly innovates to find out solutions to the challenges and deliver on timely manner to customers.</p>
                <p><i><strong className="color-logo">Integrity:</strong></i> Motivus ensures fairness, honesty and ethical behaviour in all that we do towards all of our stakeholders.</p>
                <p><i><strong className="color-logo">Excellence:</strong></i> Motivus always pursues excellence and promotes the best standard of quality possible.</p>
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="service-section association-sec">
        <Container>
          <Row>
            <div className="pbmit-heading-subheading center-align text-center">
              <h4 className="pbmit-title">Supporting Us</h4>
            </div>
          </Row>
          <Row>
            <Col sm="12">
              <OwlCarousel
                className="slider-items owl-carousel certifications-logo"
                {...clients}
              >
                <div className="item">
                  <img src={Supportingus1} alt="Supporting Us" />
                </div>
                <div className="item">
                  <img src={Supportingus2} alt="Supporting Us" />
                </div>
                <div className="item">
                  <img src={Supportingus3} alt="Supporting Us" />
                </div>
                <div className="item">
                  <img src={Supportingus4} alt="Supporting Us" />
                </div>
                <div className="item">
                  <img src={Supportingus5} alt="Supporting Us" />
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="service-section">
        <Branches />
      </div>

      <div className="service-section association-sec">
        <Container>
          <Row>
            <div className="pbmit-heading-subheading center-align text-center">
              <h4 className="pbmit-title">Our Association</h4>
            </div>
          </Row>
          <Row>
            <Col sm="12">
              <OwlCarousel
                className="slider-items owl-carousel certifications-logo"
                {...clients}
              >
                <div className="item">
                  <img src={Association1} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association2} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association3} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association4} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association5} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association6} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Adani} alt="Adani" className="mt-4" />
                </div>
                <div className="item">
                  <img className="bg-dark" src={Sterlite} alt="Sterlite" />
                </div>
                <div className="item">
                  <img src={Ambuja} alt="Ambuja" />
                </div>
                <div className="item">
                  <img src={Tata} alt="Tata" />
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="who-we-are pt-5">
        <Container>
          <h3 className="mb-5 text-white text-center fw-bold fs-1">Brief Introduction of Management</h3> 
          <Row>
            <Col lg="4" md="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <img className="mg_images" alt="" src="images/DipanjanMukherjee.jpeg"/>
                  {/* <FaUserTie /> */}
                  <h5>DIPANJAN MUKHERJEE<span className="d-block mgmt_desig">Group CEO & Director</span></h5>
                  
                  <p>
                  Dipanjan Mukherjee is an Electrical Engineer with an MBA in Marketing from IMM and has a Post Graduate Certificate in Business Management from IIM Indore. He has over 27 years of professional experience, mainly in Telecommunications and Energy sectors. He has worked in India and other Southeast Asian markets in leadership roles. A Business Manager with working experience in various functions of Telecom Operations, his forte lies in strategy, planning and operations to attain Business Goals. Gifted with a strong vision, he is credited with changing the bottom lines of a number of Profit Center Units, through Innovation, Out of the Box Strategies and Operational Excellence
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="12">
              <div className="working-block-three">
                <div className="inner-box">
                <img className="mg_images" alt="" src="images/RaviShankar.jpeg"/>
                  {/* <FaUserTie /> */}
                  <h5>RAVI SHANKAR<span className="d-block mgmt_desig">Director and Group Chief Operating Officer </span></h5>
                  
                  <p>
                  Ravi Shankar is an Engineering Graduate in Electronics. He is an accomplished leader with over 25 years of experience in operations of telecommunications and power electronics. He is committed to driving operational excellence and strategic growth. He has a proven track record of successfully managing complex operations across diverse environments, both in India and Abroad. As a passionate advocate for collaboration and continuous improvement he inspires teams to achieve their best, fostering a culture of excellence that aligns with Motivus Group’s commitment to delivering outstanding results for clients and stakeholders alike.

                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="12">
              <div className="working-block-three">
                <div className="inner-box">
                  {/* <FaUserTie /> */}
                  <img className="mg_images" alt="" src="images/PravinDubey.jpeg"/>
                  <h5>Praveen Dubey<span className="d-block mgmt_desig">Director and Chief of Strategy and Collaboration</span></h5>
                  
                  <p>
                  Praveen Dubey is an Engineering graduate working across several countries, managing ecosystems for diverse industries. With over 32 years of experience, he has now transitioned to Mentor various Startups and helps in creating markets for his investments. His portfolio of Investments includes Fiber, Oil and Gas, Water, Agro-Tech and Telecommunications. He is involved with Motivus Innovation as an Investor, Advisor and Mentor to the Group.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="service-section challenges">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <div className="img-box">
                <img src={OURCHALLENGES} alt="" />
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="pbmit-heading-subheading">
                <h4 className="pbmit-subtitle">OUR Challenges</h4>
                <h2 className="pbmit-title">
                  The challenges faced by the facility management are quite
                  significant.
                </h2>
              </div>
              <div className="challenges-content">
                <ul>
                  <li>The tenant has high power requirements</li>
                  <li>
                    99.999% uptime requirement, with no outages acceptable.
                  </li>
                  <li>High cost per GB of data generation.</li>
                  <li>
                    Frequent replacement CAPEX needed for lead-acid batteries.
                  </li>
                  <li>
                    No cell-level monitoring available for lead-acid batteries
                    leading to large-scale abuse and pilferage.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className="certifications-sec">
        <Container className="borders-sec">
          <Row>
            <Col sm="12">
              <h2>Certifications</h2>
            </Col>
            <Col sm="12">
              <OwlCarousel
                className="slider-items owl-carousel certifications-logo"
                {...clients}
              >
                <div className="item">
                  <img src={Certifications} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsOne} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsTwo} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsThree} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsFour} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsFive} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsSix} alt="" />
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="fun-section centred green-bg">
        <span className="big-text">Fun Numbers</span>
        <div className="bg-layer">
          <div className="bg-1"></div>
          <div className="bg-2"></div>
        </div>
        <Container>
          <Row>
            <Col lg="3" md="6" sm="12">
              <div className="inner-box">
                <div className="count-outer">
                  <span>2600</span>
                </div>
                <h5>Projects Completed</h5>
                <div className="icon-box">
                  <GiFilmProjector />
                </div>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12">
              <div className="inner-box">
                <div className="count-outer">
                  <span>137</span>
                </div>
                <h5>Professional Staff</h5>
                <div className="icon-box">
                  <FaUserTie />
                </div>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12">
              <div className="inner-box">
                <div className="count-outer">
                  <span>09</span>
                </div>
                <h5>Countries Covered</h5>
                <div className="icon-box">
                  <FaGlobeAmericas />
                </div>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12">
              <div className="inner-box">
                <div className="count-outer">
                  <span>845</span>
                </div>
                <h5>Satisfied People</h5>
                <div className="icon-box">
                  <FaPeopleArrows />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
};

export default Home;

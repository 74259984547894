import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import apiClient from "../Config/apiConfig";
import { LineWave } from "react-loader-spinner";

const Products = () => {
  const [getProducts, setGetProducts] = useState([]);
  const [loader, setLoader] = useState(true);

  const getAllProduct = async () => {
    try {
      const res = await apiClient.get(`api/products`);
      setGetProducts(res.data.data.product);
      setLoader(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  return (
    <>

{/* <div className="title-bar-wrapper">
        <Container>
          <Row>
            <div className="title">
              <h1>Our Companies</h1>
            </div>
            <ul className="bread-crumb pull-right">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>-</li>
              <li>our companies</li>
            </ul>
          </Row>
        </Container>
      </div> */}
      <div className="position-relative bg-video-container">
    <video
      src="videos/company-bg.mp4"
      className="position-absolute w-100 h-100 bg-video"
      autoPlay
      muted
      loop
    ></video>
    <Container className="my-5 py-5">
      <Row className="mt-5">
        <Col>
          <h1 className="text-center text-white">OUR COMPANIES</h1>
        </Col>
      </Row>
      {loader ? (
        <div className="d-flex justify-content-center">
          <LineWave height="250" width="150" color="#56b13d" ariaLabel="line-wave" />
        </div>
      ) : (
        <Row className="py-5">
          {getProducts.map((item) => (
            <Col sm="12" md="6" lg="4" key={item.id} className="mb-4">
              <div className="company-card">
                <h3>{item.name}</h3>
                <div className="about_content p-0">
                  <Link to={`/company/${item.id}`} className="btn btn-primary">
                    View More
                  </Link>
                </div>                
              </div>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  </div>
    </>
  );
};

export default Products;

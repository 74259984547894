import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import apiClient from "../Config/apiConfig";
import { LineWave } from "react-loader-spinner";
const config = {
  BASE_URL: "https://admin.motivusi.com",
};
const Gallery = () => {
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  const [getGallery, setGetGallery] = useState([]);
  const [loader, setLoader] = useState(true);

  const getAllGallery = async () => {
    try {
      const res = await apiClient.get(`api/gallery`);
      setLoader(true);
      setGetGallery(res.data);
      setLoader(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getAllGallery();
  }, []);

  const imageCards = getGallery.map((image, i) => (
    <Col lg="3" md="6" sm="12" className="mb-4" key={i}>
      <div className="gallery-img">
        <img
          onClick={() =>
            showImage(`${config.BASE_URL}/uploads/gallerys/${image.thumbal}`)
          }
          src={`${config.BASE_URL}/uploads/gallerys/${image.thumbal}`}
          alt=""
        />
      </div>
    </Col>
  ));
  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };
  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = getGallery.indexOf(imageToShow);
    if (currentIndex >= getGallery.length - 1) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = getGallery[currentIndex + 1];
      setImageToShow(nextImage);
    }
  };
  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = getGallery.indexOf(imageToShow);
    if (currentIndex <= 0) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = getGallery[currentIndex - 1];
      setImageToShow(nextImage);
    }
  };

  return (
    <>
      <div className="title-bar-wrapper">
        <Container>
          <Row>
            <div className="title">
              <h1>Gallery</h1>
            </div>
            <ul className="bread-crumb pull-right">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>-</li>
              <li>Gallery</li>
            </ul>
          </Row>
        </Container>
      </div>
      <div className="gallery-sec">
        <Container>
          <Row>
            {loader ? (
              <div className="d-flex justify-content-center">
                <LineWave
                  height="250"
                  width="150"
                  color="#56b13d"
                  ariaLabel="line-wave"
                />
              </div>
            ) : (
              <>{imageCards}</>
            )}
          </Row>
        </Container>
      </div>

      {lightboxDisplay ? (
        <div id="light-box" onClick={hideLightBox}>
          <button onClick={showPrev}>
            <AiOutlineArrowLeft />
          </button>
          <img id="light-box-img" src={imageToShow} alt="" />
          <button onClick={showNext}>
            <AiOutlineArrowRight />
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Gallery;

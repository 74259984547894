import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import apiClient from "../Config/apiConfig";
import { LineWave } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { ImMobile } from "react-icons/im";
import { AiFillPhone } from "react-icons/ai";

const Branches = () => {
  const [getBranchesData, setGetBranchesData] = useState([]);
  const [loader, setLoader] = useState(true);
  const branchApi = async () => {
    try {
      const res = await apiClient.get(`api/branches`);
      setLoader(true);
      setGetBranchesData(res.data);
      setLoader(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    branchApi();
  }, []);
  return (
    <>
      <Container>
        <Row>
          <div className="pbmit-heading-subheading center-align text-center">
            <h4 className="pbmit-title">Our Branches</h4>
          </div>
        </Row>
        <Row>
          {loader ? (
            <div className="d-flex justify-content-center">
              <LineWave
                height="250"
                width="150"
                color="#56b13d"
                ariaLabel="line-wave"
              />
            </div>
          ) : (
            getBranchesData.map((item, i) => {
              return (
                <>
                  <div className="col-lg-4">
                    <div className="branch-card">
                      <h4>{item.location}</h4>
                      <ul>
                        <li>
                          <HiOutlineOfficeBuilding />
                          <p>{item.address}</p>
                        </li>
                        {/* <li>
                          <ImMobile />
                          <p>{item.mobile}</p>
                        </li>
                        <li>
                          <AiFillPhone />
                          <p>{item.landphone}</p>
                        </li> */}
                      </ul>
                      {item.link ? (
                        <Link to={item.link} target="_blank">
                          Contact Us
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              );
            })
          )}
        </Row>
      </Container>
    </>
  );
};

export default Branches;
